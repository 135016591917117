import React, { useState } from "react";
import "./Contact.css";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Footer from "../../Footer/Footer";
import { ImArrowRight2 } from "react-icons/im";
import email from "../../../Assets/Contactus/email.png";
import map from "../../../Assets/Contactus/map.png";
import phonecall from "../../../Assets/Contactus/phone-call.png";
// import Subtract from "../../../Assets/Contactus/Subtract.svg";
import Header from "../../Header/header_home";
// import Link from "@mui/material/Link";
import axios from "../../../network/api";
// import FormData from "form-data";
import { baseUrl } from "../../../network/constants/Constants";
import Alert from "@mui/material/Alert";
import CustomAlert from "../CustomAlert/CustomAlert";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
const Contact = () => {
  const [visibility, setVisibility] = React.useState(false);

  const popupCloseHandler = (e) => {
    setVisibility(e);
  };

  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [contactMail, setContactMail] = useState("");
  const [contactQuery, setContactQuery] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [contactError, setContactError] = useState("");
  const [loading, setLoading] = useState("");

  function handleSubmitContact(e) {
    var filter = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!contactName.trim()) {
      setContactError("Please enter your name!");
    } else if (contactPhone.length !== 10) {
      setContactError("Please enter your phone number!");
    } else if (!filter.test(contactMail.toLowerCase())) {
      setContactError("Please enter a valid mail address!");
    } else if (!contactQuery) {
      setContactError("Please select Query!");
    } else if (contactMessage.length === 0) {
      setContactError("Please enter your message!");
    } else {
      submitContactUs();
    }
  }

  const submitContactUs = (props) => {
    setLoading(true);

    const payload = {
      name: contactName,
      phone: contactPhone,
      email: contactMail,
      enquiry_type: contactQuery,
      message: contactMessage,
    };

    console.log("payload: " + JSON.stringify(payload));

    axios
      .post(baseUrl + "/webapp/contacts/create/", payload)
      .then((response) => {
        console.log("<><><><><><>" + response.data);
        setLoading(false);
        setVisibility(true);
        // setContactError("");
        // setContactMail("");
        // setContactQuery("");
        // setContactMessage("");
        // setContactName("");
        // setContactPhone("");
      })
      .catch((error) => {
        console.log(error + "errrorrriiii");
        setLoading(true);
      });
  };
  // }
  return (
    <div className="contactus" id="contactus">
      <div className="contact-head">
        <Header color="black" />
      </div>

      <div className="contactus-div">
        <Container>
          <Row>
            <Col lg={6} xs={12} sm={6} className="contactus-colone">
              <Row>
                <span className="contactus-head">Contact Us</span>
                <p className="contactus-subhead">
                  Get the info you're looking for
                </p>
              </Row>

              <Row>
                <div className="contactus-card">
                  <img src={email} alt="mail"></img>
                  <br></br>
                  <span>Chat with Sales</span>
                  <p>
                    Chat with our friendly sales team for more info on our
                    Products
                  </p>
                  <a
                    href="mailto://info@firafoods.com"
                    className="contactus-address"
                  >
                    info@firafoods.com
                  </a>
                </div>
              </Row>
              <Row>
                <div className="contactus-card">
                  <img src={map} alt="map"></img>
                  <br></br>

                  <span>visit us </span>
                  <p>Visit us to know more about us</p> 
                  <p className="corporate-Office" >Corporate Office : <br/>  <a
                    className="contactus-address"
                    href="https://www.google.com/maps/place/Infra+Vantage,+Kakkanad,+Kerala+682030/@10.0086398,76.3454842,218m/data=!3m1!1e3!4m6!3m5!1s0x3b080c92004657f9:0xf68eb26f34edb197!8m2!3d10.0086345!4d76.3462701!16s%2Fg%2F12jvyrt73?entry=ttu&g_ep=EgoyMDI1MDIwMi4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                  > Near Infra Vantage, Kakkanad, Kochi, Kerala, India - 682037
                    
                  </a></p>

                  <p className="registered-Office">Registered office : <br/> <a
                    className="contactus-address"
                    href="https://www.google.com/maps/place/Rizz+towers/@10.5204734,76.1942803,870m/data=!3m2!1e3!4b1!4m6!3m5!1s0x3ba7ef1f50c8d2c7:0xa8e3cbdb9be9f383!8m2!3d10.5204734!4d76.1968552!16s%2Fg%2F11qphq80fb?entry=ttu&g_ep=EgoyMDI1MDIwMi4wIKXMDSoASAFQAw%3D%3D"
                    target="_blank"
                  > Rizz Tower, Ayyanthole, Thrissur, Kerala, India - 680003
                  </a></p>


                  
                  
                </div>
              </Row>
              <Row>
                <div className="contactus-card">
                  <img src={phonecall} alt="phone"></img>
                  <br></br>

                  <span>Call Us</span>
                  <p> Mon - Fri, 10 am - 7pm</p>
                  <a href="tel:+91 9562 756 699" className="contactus-address">
                    +91 9562 756 699
                  </a>
                </div>
              </Row>
            </Col>

            <Col lg={6} xs={12} sm={6} className="contactus-coltwo">
              <div className="getin-touchdiv">
                <span className="contact-formhead">Get In Touch With Us</span>
                <div className="contactus-form">
                  <label className="contact_label">Name</label>
                  <div className="textfield-div">
                    <input
                      type="text"
                      className="input-field"
                      placeholder="Enter name"
                      value={contactName}
                      onChange={(e) => setContactName(e.target.value)}
                    ></input>
                  </div>
                  <label className="contact_label">Phone No</label>
                  <div className="textfield-div">
                    <input
                      type="tel"
                      className="input-field"
                      placeholder="Enter Number"
                      value={contactPhone}
                      onChange={(e) => setContactPhone(e.target.value)}
                    ></input>
                  </div>

                  <label className="contact_label">Mail ID</label>
                  <div className="textfield-div">
                    <input
                      type="email"
                      className="input-field"
                      placeholder="Enter Mail"
                      value={contactMail}
                      onChange={(e) => setContactMail(e.target.value)}
                    ></input>
                  </div>

                  <label className="career_label">Subject</label>
                  <br></br>
                  <div className="textfield-div">
                    <select
                      id="cars"
                      name="cars"
                      className="input-field careerselect"
                      placeholder="Select"
                      value={contactQuery}
                      onChange={(e) => setContactQuery(e.target.value)}
                    >
                      <option value="Select Query">Select Query</option>
                      <option value="General Enquiry">General Enquiry</option>
                      <option value="Distributor Enquiry">
                        Distributor Enquiry
                      </option>
                      <option value="Customer Feedback">
                        Customer Feedback
                      </option>
                      <option value="Bulk Purchases">Bulk Purchases</option>
                    </select>
                  </div>
                  <label className="contact_label">Message</label>
                  <div className="textarea-div">
                    <textarea
                      rows={6}
                      className="input-field message"
                      placeholder="Enter Message"
                      value={contactMessage}
                      onChange={(e) => setContactMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="contacterror">
                    {contactError && (
                      <Alert
                        variant="standard"
                        size="small"
                        severity="error"
                        className="contactalert"
                      >
                        {contactError}
                      </Alert>
                    )}
                  </div>

                  <button className="contact-btn" onClick={handleSubmitContact}>
                    Submit&nbsp;
                    <ImArrowRight2 size={12} />
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {loading && (
        <Box sx={{ display: "center", justifyContent: "center" }}>
          <CircularProgress />
        </Box>
      )}
      <CustomAlert onClose={popupCloseHandler} show={visibility}>
        <h5>Form Submitted</h5>
      </CustomAlert>
      <div className="contactus-footerdiv">
        <Footer />
      </div>
    </div>
  );
};

export default Contact;
